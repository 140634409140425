import React from 'react';
import cx from 'classnames';
import data from './data.json';

import Container from '@components/Container';

import styles from './styles.module.css';

const Wrapper = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

const Title = ({ children }) => <h2 className={styles.title}>{children}</h2>;
const Paragraph = ({ str }) => <p className={styles.paragraph}>{str}</p>;

const ParagraphList = ({ children: paragraphs }) =>
  paragraphs.map(str => <Paragraph str={str} />);

const ListItem = ({ children, className }) => (
  <li className={cx(styles.item, className)}>{children}</li>
);

const BlockItemList = ({ children }) => (
  <ul>
    {children.map(str => (
      <ListItem>{str}</ListItem>
    ))}
  </ul>
);

const BlockItemWithList = ({ paragraphs, list }) => {
  return (
    <React.Fragment>
      {paragraphs.map(str => (
        <p className={styles.paragraphListItem}>{str}</p>
      ))}
      <BlockItemList>{list}</BlockItemList>
    </React.Fragment>
  );
};

const BlockList = ({ children: items }) => (
  <ul>
    {items.map(item => {
      return typeof item === 'object' ? (
        <BlockItemWithList {...item} />
      ) : (
        <ListItem className={styles.counter}>{item}</ListItem>
      );
    })}
  </ul>
);

const Block = ({ title, list }) => (
  <Wrapper>
    <Title>{title}</Title>
    <BlockList>{list}</BlockList>
  </Wrapper>
);

export default function index() {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <Container>
          <h1 className={styles.header}>{data.header}</h1>
          {[data.intro, data.definitions].map(({ title, paragraphs }) => (
            <Wrapper>
              <Title>{title}</Title>
              <ParagraphList>{paragraphs}</ParagraphList>
            </Wrapper>
          ))}
          {[data.basics, data.personal, data.signature, data.accept].map(
            block => (
              <Block {...block} />
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
